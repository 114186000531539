import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import CustomLink from './CustomLink'

class CardSmall extends React.Component {
  render() {
    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="pb-30 d-flex" >
        <div className="card promoting-card flex-fill">
          <div className="card-body d-flex flex-row" style={{ minHeight: '80px', maxHeight: '80px',  alignItems: 'center' }} >
            <div style={{ minWidth: '65px', maxWidth: '65px', }} >
              <GatsbyImage image={this.props.avatar} className="mr-1" alt={`company logo for ${this.props.title}`} />
            </div>

            <div>
              <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-large text-large">
                {this.props.title}
              </h3>
            </div>
          </div>

          <div className="card-body-prods">
            <p className="text-medium text-gray-dark">
              {this.props.sectordescription}
            </p>
          </div>

          <div className="card-footer">
            <div className="text-center">
              <CustomLink
                link={this.props.link}
                className="btn btn-block btn-md btn-mdb-color noeffect"
                aria-label={`Visit ${this.props.title}`}
              >
                <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                {this.props.linktext}
              </CustomLink>
            </div>
          </div>
        </div>
      </MDBCol>
    )
  }
}
export default CardSmall